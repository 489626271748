.loading.cover-content {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.loading.cover-page {
  position: fixed;
  width: 100%;
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
}

.loading .ant-spin {
  color: #3e79f7;
}

.image-container {
  position: relative;
  margin-right: 10px;
  border: 1px solid black;
  border-radius: 5px;
}

.image-container .delete-icon {
  position: absolute;
  top: 5px;
  right: 5px;
  background: #fff;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  text-align: center;
  line-height: 25px;
  cursor: pointer;
}




